<template>
  <div class="container">
      <div class="form">
        <div class="window verification__window">
          <div class="account__container">
            <div class="verification__icon">
              <img src="@/assets/images/verification-email.svg" alt="email">
            </div>
            <h1 class="verification__title">
              Click on the verification link we have sent to {{signedUpEmail}} to confirm it.
            </h1>
            <p>
              <router-link class="verification__link" :to="{ name: 'home' }">
                Go to Main
              </router-link>
            </p>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import {MODULE_NAME} from "@/store/modules/auth";

  export default {
    name: 'SignupSuccess',

    computed: {
      signedUpEmail() {
        return this.$store.state[MODULE_NAME].signup.data.email
      }
    },
    beforeDestroy() {}
  }
</script>
